import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, from } from 'apollo-link'

import config from './config'
import getToken from './store/getToken'
import omitDeep from './helpers/omitDeep'
console.log('APOLLO', config.api)
const httpLink = createHttpLink({
  uri: `${config.api.url}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }
})

// Strip __typename from variables
const middleWareLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename')
  }
  return forward(operation)
})
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
}
const apollo = new ApolloClient({
  link: from([middleWareLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
  connectToDevTools: true,
  defaultOptions,
})

export default apollo
